import request from '@/utils/request'

// 查询用户注销记录列表
export function listUserLogout(query) {
  return request({
    url: '/biz/user-cancel/list',
    method: 'get',
    params: query
  })
}

// 查询用户注销记录分页
export function pageUserLogout(query) {
  return request({
    url: '/biz/user-cancel/page',
    method: 'get',
    params: query
  })
}

// 查询用户注销记录详细
export function getUserLogout(data) {
  return request({
    url: '/biz/user-cancel/detail',
    method: 'get',
    params: data
  })
}

// 新增用户注销记录
export function addUserLogout(data) {
  return request({
    url: '/biz/user-cancel/add',
    method: 'post',
    data: data
  })
}

// 修改用户注销记录
export function updateUserLogout(data) {
  return request({
    url: '/biz/user-cancel/edit',
    method: 'post',
    data: data
  })
}

// 删除用户注销记录
export function delUserLogout(data) {
  return request({
    url: '/biz/user-cancel/delete',
    method: 'post',
    data: data
  })
}
